import ERROR_MESSAGES from './errors';
import {formatMessage} from 'utilsOld/formatMessage';

function getErrorUrl(id) {
  const ERROR_URL = window.location.origin + window.location.pathname + `#/errors/#`;

  return ERROR_URL + id;
}

function outMessage(id, message) {
  return formatMessage('{0} - {1}. \nSee: {2}', [id, message, getErrorUrl(id)]);
}

function getTestId(id) {
  let method = 'log';

  if (/^E\d+$/.test(id)) {
    method = 'error';
  } else if (/^W\d+$/.test(id)) {
    method = 'warn';
  }

  return method;
}

export class D5Error {
  static createErrorMessage(id, args) {
    return outMessage(id, formatMessage(ERROR_MESSAGES[id], args));
  }

  /**
   * @param {string} id - E9999 or W10000
   * @param {Array<string>} [details] - ['Something1', 'Something2']
   * @return {Error}
   */
  static create = (id, details = []) => {
    return new Error(D5Error.createErrorMessage(id, details));
  };

  static testID = id => {
    return getTestId(id);
  };

  /**
   * @param {string} id - E9999 or W10000
   * @param {Array<string | *>} [details] - ['Something1', 'Something2']
   */
  static log(id, details = []) {
    let method = getTestId(id);

    console[method](D5Error.createErrorMessage(id, details));
  }
}
