import {LOGIN_ROUTE} from './objects';

const WAPI_USEPATH = process.env.REACT_APP_WAPI_USEPATH;
const USER_FOLDER_PATH = process.env.REACT_APP_USER_FOLDER_PATH;

export const wapiUrl = `${WAPI_USEPATH ? window.location.pathname : '/'}wapi`;
export const userScriptBaseUrl = `.${USER_FOLDER_PATH ? `/${USER_FOLDER_PATH}/scripts` : '/user-scripts'}`;
export const userResourcesBaseUrl = `.${USER_FOLDER_PATH ? `/${USER_FOLDER_PATH}/resources` : '/user-resources'}`;
export const userFontsBaseUrl = `${userResourcesBaseUrl}/fonts/customFonts.css`;
export const userImagesBaseUrl = `${userResourcesBaseUrl}/img`;

export const urlSignIn = wapiUrl + LOGIN_ROUTE;
export const urlSignUp = wapiUrl + '/Sys_Login/RegisterUser';
export const urlActivation = wapiUrl + '/Sys_Login/ActivateUser';
export const urlRestorePass = wapiUrl + '/Sys_Login/RestorePasswordRequest';
export const urlChangePass = wapiUrl + '/Sys_Login/ChangePassword';
export const urlSignOut = wapiUrl + '/logout';

export const urlSysSubsystems = wapiUrl + '/Sys_Subsystems';
