import {system} from 'services/objects';

const {DX_CONTROLS} = system;

export type D5ID = string | number;

export type USmallInt = 0 | 1;

export enum PAGINATION_TYPE {
  AUTO = 1,
  PAGE_SELECTOR = 2,
  BY_REQUEST = 3,
  NONE = 4,
}

export enum DEFAULT_TREE_EXPAND_KIND {
  FULL_COLLAPSED = 0,
  FULL_EXPANDED = 1,
  EXPAND_TO_LEVEL = 2,
}

export enum DEFAULT_MODAL_SIZE {
  AUTO = 0,
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  FULL = 4,
}

export enum TOOLBAR_VIEW_TYPE {
  NONE = 0,
  TOP_AND_LINE = 1,
  TOP = 2,
  LINE = 3,
}

export enum TOTAL_ROW_KIND {
  NONE = 0,
  BY_SELECTED = 1,
  ALL_DATA = 2,
}

export enum RELATION_TYPE {
  MASTER = 1,
  DETAIL = 2,
  CUSTOM = 3,
}

export enum DECORATION_DISPLAY_TYPE {
  LABEL = 0,
  IMAGE = 1,
  ICON = 2,
  LINK = 3,
  HTML = 4,
  FILE_VIEWER = 5,
}

export const DECORATION_DISPLAY_TYPE_TEXT = {
  [DECORATION_DISPLAY_TYPE.LABEL]: 'text',
  [DECORATION_DISPLAY_TYPE.IMAGE]: 'image',
  [DECORATION_DISPLAY_TYPE.ICON]: 'icon',
  [DECORATION_DISPLAY_TYPE.LINK]: 'link',
  [DECORATION_DISPLAY_TYPE.HTML]: 'html',
  [DECORATION_DISPLAY_TYPE.FILE_VIEWER]: 'file-viewer',
} as const;

export enum FormType {
  //server types
  LIST = 1,
  EDIT = 2,
  TREE = 3,
  REPORT = 4,
  MULTI_EDIT = 5,
  TILE_LIST = 6,
  KANBAN = 7,
  FREE_FORM = 99,
  HEADER_FILTER = -1,
  FILTER_FORM = -2,
  QR_SCANNER = -3,
  FormPreview = -4,
  FILE_PREVIEW_FORM = -5,
}

export enum FormViewMode {
  FULL_SCREEN = 1,
  MODAL = 2,
  IN_NEW_WINDOW = 3,
}

export enum D5TitlePosition {
  Top = 0,
  Left = 1,
  Right = 2,
}

export enum FormCreateMode {
  ADD = 'add',
  ADD_CHILD = 'addChild',
  EDIT = 'edit',
  VIEW = 'view',
  MULTI_EDIT = 'multiEdit',
  REPORT = 'report',
  DELETE = 'delete',
  FORM_SELECT = 'formSelect',
  FORM_MULTI_SELECT = 'formMultiSelect',
  LIST = 'listForm',
  TREE = 'treeForm',
  FREE = 'freeForm',
}

export enum TitleType {
  Icon = 1,
  Title,
  IconAndTitle,
}

export enum GroupType {
  PANEL = 0,
  TAB_PANEL = 1,
  TAB = 2,
  ACCORDION_ITEM = 3,
  TABLE_HEADER = 4,
  BUTTONS = 5,
  ACCORDION = 7,
  FORM_BUTTON_GROUP = 8,
  TOOLBAR_BUTTONS_SECTION = 9,
}

export enum BUTTON_TYPE {
  CUSTOM = 0,
  ADD = 1,
  EDIT = 2,
  DELETE = 3,
  REFRESH = 4,
  FILTER = 5,
  MULTI_EDIT = '__multiEdit',
  GOTO = 6,
  REPORT = 7,
  COPY = 8,

  LOAD_ALL = 9,
  SELECT_ALL = 10,
  DESELECT_ALL = 11,
  EXPORT = 12,
  EXPORT_ALL = '__exportAll',
  EXPORT_SELECTED = '__exportSelected',
  TOGGLE_TOTALS = '__toggleTotals',
  TOGGLE_TOTALS_NONE = '__toggleTotalsNone',
  TOGGLE_TOTALS_BY_SELECTED = '__toggleTotalsBySelected',
  TOGGLE_TOTALS_ALL_DATA = '__toggleTotalsAllData',
  REVERT_TABLE_OPTIONS = '__revertTableOptions',
  SELECT_FILTER_TEMPLATE = '__selectFilterTemplate',
  COPY_FILTER_AS_URL = '__copyFilterAsUrl',
  TABLE_SETTINGS = 13,
  MOVE_UP = 14,
  MOVE_DOWN = 15,
  MOVE_RIGHT = 16,
  MOVE_LEFT = 17,
  CLEAR_QUICK_FILTER = 18,
  SHOW_FILTER_ROW = 19,
  SET_IS_PASSED = 20,
  CLEAR_IS_PASSED = 21,
  ADD_CHILD = 22,
  SHOW_FILTER_DOCK_PANEL = 23,
  REPORT_GROUP = 24,
  EXPAND_ALL = 25,
  COLLAPSE_ALL = 26,
  VIEW = 27,
  EDIT_DOCK_PANEL = 28,
  HEADER_CONTEXT_MENU_BUTTON = -1000,
}

export enum HEADER_CM_BUTTON_TYPE {
  CLEAR_FILTER = 'clearFilter',
  HIDE_COLUMN = 'hideColumn',
  UNFIX_COLUMNS = 'unfixColumns',
  FIX_COLUMNS = 'fixColumns',
  HELP_BUTTON = 'helpButton',
}

export type headerContextMenuButtons = HEADER_CM_BUTTON_TYPE[];

export enum USER_EVENT_TYPE {
  ON_FIELD_VALUE_CHANGED = 1,
  ON_BUTTON_CLICK = 2,
  ON_INIT_FORM = 3,
  ON_SELECTION_CHANGED = 4,
  ON_FILTER_VALUE_CHANGED = 5,
  ON_SHOW_FORM = 6,
  ON_SAVE_FORM = 24,
  ON_CLOSE_FORM = 25,
  ON_FOCUSED_CELL_CHANGED = 23,
  ON_ROW_UPDATED = 19,
  ON_ROW_INSERTED = 14,
  ON_ROW_DELETED = 17,
  ON_ROW_INSERTING = 13,
  ON_ROW_EDITING = 9,
  ON_BEFORE_ROW_EDITING = 28,
  ON_BEFORE_ROW_INSERTING = 27,
  ON_ROW_DELETING = 16,
  ON_FILTER_APPLY = 26,
  ON_INIT_LOOKUP_FILTER = 15,
  ON_INIT_LOOKUP_FIELD = 22,
  ON_SUBFORM_READY = 29,
  ON_CUSTOMIZE_ROW = 30,
  ON_CUSTOMIZE_CELL = 31,
  ON_DATA_LOADED = 32,
  ON_CELL_CLICK = 33,
  ON_CELL_DOUBLE_CLICK = 34,
  ON_KEY_DOWN = 35,
  ON_CUSTOMIZE_ROW_EDITOR = 36,
  ON_CARD_DRAG_DROP_START = 38,
  ON_CARD_DRAG_DROP_FINISH = 39,
  ON_CARD_CLICK = 40,
  ON_CARD_DOUBLE_CLICK = 41,
  ON_DIAGRAM_CLICK = 42,
  ON_DIAGRAM_DOUBLE_CLICK = 43,
  ON_APPLY = 44,
}

export enum TOTAL_FUNC_KINDS {
  NONE = 0,
  SUM = 1,
  COUNT = 2,
  AVG = 3,
  MIN = 4,
  MAX = 5,
}

export enum OrientationStr {
  COLUMN = 'col',
  ROW = 'row',
}

export enum GroupOrientation {
  COLUMN = 1,
  ROW = 2,
}

export type SplitterHandle = 'bottom' | 'left' | 'right' | 'top' | undefined;

export enum FormToolBarButtons {
  SAVE = 'save',
  CLOSE = 'close',
  APPLY = 'apply',
  SELECT = 'select',
  SAVE_CLOSE = 'saveClose',
  CANCEL = 'cancel',
}

export interface CloseFormResolveOptions {
  button: FormToolBarButtons;
  userData: any;
}

export interface OpenFormOptions {
  actionFormId: string;
  formType: FormType;
  formKey?: string;
  actionFormName?: string;
  id?: D5ID[];
  viewMode: FormViewMode;
  userData?: any;
  parentFormID?: string;
  parentFormKey?: string;
  parentFormFilter?: Record<string, any>;
  createMode?: FormCreateMode;
  options?: {
    defaultFilter?: string;
    isCalledByReportGroup?: boolean;
    reportName?: string;
    selectedKeys?: Record<string, any>;
  };
}

export interface ICreateEditUrl {
  parentRoute?: string;
  mode?: FormCreateMode;
  formName: string;
  id?: string | number;
}

export interface IEnumDataSource {
  key: number | string;
  value: any;
}

export enum APIoperations {
  CREATE = 'Ins',
  READ = 'List',
  UPDATE = 'Mod',
  DELETE = 'Del',
}

export type DX_TYPES = typeof DX_CONTROLS[keyof typeof DX_CONTROLS];

export enum BUTTON_POSITION {
  NONE = 0,
  TOOLBAR = 1,
  CONTEXT_MENU = 2,
  ALL = 3,
  IN_GROUP = 4,
  EDITOR_BUTTON = 5,
  TILE_BUTTON = 6,
  HEADER_CONTEXT_MENU = -1000,
}

export type RowPerPage = number | 'all' | undefined;

export enum ConfirmDialogResults {
  CONFIRMED = 'yes',
  NO_CONFIRMED = 'no',
}

export enum MOUSE_KEY {
  LEFT,
  MIDDLE,
  RIGHT,
}

export enum ROW_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum LanguageCode {
  ua = 'ua',
  ru = 'ru',
  en = 'en',
}

export enum Locale {
  uk = 'uk',
  ru = 'ru',
  en = 'en',
}

export enum SNACKBAR_TYPES {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Confirm = 'confirm',
  Custom = 'custom',
  Help = 'help',
}

export enum NOTIFICATION_TYPES {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Confirm = 'confirm',
  Custom = 'custom',
  Help = 'help',
  Prompt = 'prompt',
}

export interface IUser {
  sid: string;
  Name: string;
  PersonID: number | null;
  UserID: number | null;
  servicePrefix: string;
}

export enum ExpandOperation {
  expandAll = 'expandAll',
  collapseAll = 'collapseAll',
  expandRow = 'expandRow',
  collapseRow = 'collapseRow',
}

export interface IExpandedState {
  operation: ExpandOperation;
  keys?: D5ID[];
}

export enum ActiveMode {
  section = 'section',
  favorites = 'favorites',
}

export enum HierarchyRequestKind {
  allParent = 1,
  allChild = 2,
  fullPath = 3,
}

export enum AlternativeItems {
  Default = 'default',
  Show = 'show',
  Hide = 'hide',
}

export enum FILTER_LAYOUT {
  DOCK_PANEL = 'dock-panel-filter',
  HEADER_FILTER = 'header-filter',
  LAYOUT = 'layout-filter',
  FORM_FILTER = 'form-filter',
}

export enum FILTER_OPERATIONS_ID {
  equal = 1,
  contains = 2,
  notequal = 3,
  less = 4,
  greater = 5,
  lessorequal = 6,
  greaterorequal = 7,
  between = 8,
  isblank = 9,
  isnotblank = 10,
  isanyof = 11,
  isnotanyof = 12,
  startwith = 13,
  bywords = 14,
}

export enum FILTER_OPERATIONS {
  equal = '=',
  notequal = '<>',
  less = '<',
  greater = '>',
  lessorequal = '<=',
  greaterorequal = '>=',
  contains = 'contains',
  between = 'between',
  isanyof = 'isanyof',
  isnotanyof = 'isnotanyof',
  isblank = 'isblank',
  isnotblank = 'isnotblank',
  startwith = 'startwith',
  bywords = 'bywords',
}

export enum FIELD_EDITOR_TYPE {
  IMAGE = 'image',
  BOOL_CB = 'bool',
  DATE = 'date',
  DATETIME = 'datetime',
  DATE_TWIN = 'dateTwin',
  MULTI_SELECT = 'multiSelect',
  SELECT = 'select',
  NUMBER = 'number',
  NUMBER_TWIN = 'numberTwin',
  TEXT = 'string',
  TEXT_AREA = 'textArea',
  IS_BLANK = 'isblank',
  IS_NOT_BLANK = 'isnotblank',
  ANY_OF = 'anyof',
  HTML_EDITOR = 'htmlEditor',
  FILE = 'file',
  SWITCH = 'switch',
  DROP_DOWN_BOX = 'dropDownBox',
  QR_SCANNER = 'qrScanner',
  DIAGRAM = 'diagram',
  HEADER_FILTER_BOOL = 'headerFilterBool',
  HEADER_FILTER_DICTIONARY = 'headerFilterDic',
  RANGE_SLIDER = 'rangeSlider',
  SLIDER = 'slider',
  LIST_SELECTOR = 'listSelector',
  DUAL_LIST_SELECTOR = 'dualListSelector',
  FILE_PREVIEW = 'filePreview',
  COLOR_BOX = 'colorBox',
}
