export const objects = {
  SysAppObjects: 'Sys_AppObjects',
  SysFormFilterFields: 'Sys_FormFilterFields',
  SysAppObjectOperations: 'Sys_AppObjectOperations',
  SysSubsystems: 'Sys_Subsystems',
  SysForms: 'Sys_Forms',
  SysFormFields: 'Sys_FormFields',
  Sys_FormFilterTemplates: 'Sys_FormFilterTemplates',
  SysFormGroups: 'Sys_FormGroups',
  SysUsers: 'Sys_Users',
  SysRoles: 'Sys_Roles',
  SysRoleOperationAccess: 'Sys_RoleOperationAccess',
  SysRoleFieldsRestriction: 'Sys_RoleFieldsRestriction',
  SysRoleSubsystemAccess: 'Sys_RoleSubsystemAccess',
  SysPositions: 'Sys_Positions',
  SysPositionRoles: 'Sys_PositionRoles',
  SysPositionAccess: 'Sys_PositionAccess',
  SysUserPositions: 'Sys_UserPositions',
  SysUserPositionAdditionAccess: 'Sys_UserPositionAdditionAccess',
  SysFormButtons: 'Sys_FormButtons',
  SysFormFieldEvents: 'Sys_FormFieldEvents',
  SysSubForms: 'Sys_SubForms',
  SysAppObjectFilters: 'Sys_AppObjectFilters',
  SysFormButtonEvents: 'Sys_FormButtonEvents',
  SysFormFilterFieldEvents: 'Sys_FormFilterFieldEvents',
  SysFormDecorationElements: 'Sys_FormDecorationElements',
  SysFormButtonFillingRules: 'Sys_FormButtonFillingRules',
  SysReportList: 'Sys_ReportList',
} as const;

export const fields = {
  _id: '_id',
  ID: 'ID',
  UserID: 'UserID',
  PersonID: 'PersonID',
  ObjectID: 'ObjectID',
  ObjectID_Name: 'ObjectID.Name',
  ObjectID_KeyObjectFieldID_Name: 'ObjectID.KeyObjectFieldID.Name',
  ObjectID_KeyObjectFieldID_FieldType: 'ObjectID.KeyObjectFieldID.FieldType',
  Toolbar_View_Type: 'ToolbarViewType',

  Order: 'Order',
  IsDynamicField: 'IsDynamicField',
  Columns: 'Columns',
  Name: 'Name',
  Width: 'Width',
  ButtonWidth: 'ButtonWidth',
  GroupWidth: 'GroupWidth',
  Cols: 'Cols',
  Rows: 'Rows',
  IsResizable: 'IsResizable',
  LocationOnGrid: 'LocationOnGrid',
  ParentSubsystemID: 'ParentSubsystemID',
  FormID: 'FormID',
  FormID_Type: 'FormID.Type',
  ActionFormName: 'ActionFormName',
  DialogActionFormName: 'DialogActionFormName',
  FormName: 'FormName',
  FormID_Name: 'FormID.Name',
  FormID_Title: 'FormID.Title',
  DefaultFilter: 'DefaultFilter',
  AutoRefreshTimeout: 'AutoRefreshTimeout',
  IsAutoRefresh: 'IsAutoRefresh',
  IsFormFilterTemplate: 'IsFormFilterTemplate',
  FormID_ViewMode: 'FormID.ViewMode',
  Form_Open_Mode: 'FormOpenMode',
  FormID_ObjectID: 'FormID.ObjectID',
  FormID_ObjectID_Name: 'FormID.ObjectID.Name',
  FormID_ObjectID_KeyObjectFieldID_Name: 'FormID.ObjectID.KeyObjectFieldID.Name',
  IsGroup: 'IsGroup',
  IsRefreshOnDemand: 'IsRefreshOnDemand',
  IsFilterOnShow: 'IsFilterOnShow',
  IsFixedColumnWidth: 'IsFixedColumnWidth',
  IsFixedColumnOrder: 'IsFixedColumnOrder',
  IsFixedOrder: 'IsFixedOrder',
  IsFixedLeft: 'IsFixedLeft',
  IsShowColumnCustomization: 'IsShowColumnCustomization',
  IsShowButtonCustomization: 'IsShowButtonCustomization',
  IsTreeDragDropAllowed: 'IsTreeDragDropAllowed',
  IsUseAlternativeRowColor: 'IsUseAlternativeRowColor',
  IsShowColumnHeader: 'IsShowColumnHeader',
  IsShowColumnLines: 'IsShowColumnLines',
  IsShowRowLines: 'IsShowRowLines',
  IsFormBodyScrollable: 'IsFormBodyScrollable',
  IsShowBorder: 'IsShowBorder',
  IsCustomConditionList: 'IsCustomConditionList',
  CustomConditionList: 'CustomConditionList',
  DefaultTreeExpandKind: 'DefaultTreeExpandKind',
  DefaultTreeExpandLevel: 'DefaultTreeExpandLevel',
  DefaultModalSize: 'DefaultModalSize',
  FixedOrderField: 'FixedOrderField.Name',
  IsColumnAutoWidth: 'IsColumnAutoWidth',
  LookupObjectName: 'LookupObjectName',
  LookupKeyFieldName: 'LookupKeyFieldName',
  LookupDisplayFieldName: 'LookupDisplayFieldName',
  LookupSearchKind: 'LookupSearchKind',
  DefaultGroupFieldID: 'DefaultGroupFieldID',
  DefaultGroupFieldID_Name: 'DefaultGroupFieldID.Name',
  DefaultGroupFieldID_LinkedObjectID_Name: 'DefaultGroupFieldID.LinkedObjectID.Name',
  DefaultGroupFieldID_LinkedObjectID_DisplayObjectFieldID_Name:
    'DefaultGroupFieldID.LinkedObjectID.DisplayObjectFieldID.Name',
  DefaultGroupFieldID_LinkedObjectID_KeyObjectFieldID_Name: 'DefaultGroupFieldID.LinkedObjectID.KeyObjectFieldID.Name',
  ObjectFieldID: 'ObjectFieldID',
  ObjectFieldID_Name: 'ObjectFieldID.Name',
  ObjectFieldID_IsKey: 'ObjectFieldID.IsKey',
  ObjectFieldID_IsFile: 'ObjectFieldID.IsFile',
  ObjectFieldID_FieldType: 'ObjectFieldID.FieldType',
  ObjectFieldID_NumberScale: 'ObjectFieldID.NumberScale',
  ObjectFieldID_IsTimeAllowed: 'ObjectFieldID.IsTimeAllowed',
  ObjectFieldID_NumberPrecision: 'ObjectFieldID.NumberPrecision',
  ObjectFieldID_IsDisplayField: 'ObjectFieldID.IsDisplayField',
  ObjectFieldID_LinkedObjectID: 'ObjectFieldID.LinkedObjectID',
  ObjectFieldID_LinkedObjectID_Name: 'ObjectFieldID.LinkedObjectID.Name',
  ObjectFieldID_LinkedObjectID_FormForSelectByDefault: 'ObjectFieldID.LinkedObjectID.FormForSelectByDefault',
  ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Type: 'ObjectFieldID.LinkedObjectID.FormForSelectByDefault.Type',
  ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Name: 'ObjectFieldID.LinkedObjectID.FormForSelectByDefault.Name',

  ObjectFieldID_DisplayObjectFieldID: 'ObjectFieldID.LinkedObjectID.DisplayObjectFieldID',
  ObjectFieldID_DisplayObjectFieldID_Name: 'ObjectFieldID.LinkedObjectID.DisplayObjectFieldID.Name',
  ObjectFieldID_DisplayObjectFieldID_FieldType: 'ObjectFieldID.LinkedObjectID.DisplayObjectFieldID.FieldType',

  ObjectFieldID_KeyObjectFieldID: 'ObjectFieldID.LinkedObjectID.KeyObjectFieldID',
  ObjectFieldID_KeyObjectFieldID_Name: 'ObjectFieldID.LinkedObjectID.KeyObjectFieldID.Name',
  ObjectFieldID_KeyObjectFieldID_FieldType: 'ObjectFieldID.LinkedObjectID.KeyObjectFieldID.FieldType',

  ObjectID_DisplayObjectFieldID_Name: 'ObjectID.DisplayObjectFieldID.Name',

  DisplayField: 'DisplayField',
  GroupID: 'GroupID',
  KeyObjectFieldID: 'KeyObjectFieldID',
  KeyObjectFieldID_Name: 'KeyObjectFieldID.Name',
  DisplayObjectFieldID: 'DisplayObjectFieldID',
  DisplayObjectFieldID_Name: 'DisplayObjectFieldID.Name',
  GroupID_ParentGroupID: 'GroupID.ParentGroupID',
  GroupID_Title: 'GroupID.Title',
  Title: 'Title',
  IsShowTitle: 'IsShowTitle',
  IsReadOnly: 'IsReadOnly',
  IsDownloadLink: 'IsDownloadLink',
  IsMultiselect: 'IsMultiselect',
  IsVisible: 'IsVisible',
  IsMultiValueField: 'IsMultiValueField',
  IsUseTime: 'IsUseTime',
  SortByDefault: 'SortByDefault',
  AppObjectID: 'AppObjectID',
  AppObjectID_Name: 'AppObjectID.Name',
  IsKey: 'IsKey',
  FieldType: 'FieldType',
  LineCount: 'LineCount',
  ViewFormat: 'ViewFormat',
  EditFormat: 'EditFormat',
  KeyDictionaryField: 'KeyDictionaryField',
  ButtonType: 'ButtonType',
  Position: 'Position',
  IconType: 'IconType',
  ActionFormID: 'ActionFormID',
  IsStartToolbarSection: 'IsStartToolbarSection',
  ActionFormViewMode: 'ActionFormID.ViewMode',
  Type: 'Type',
  EditMode: 'EditMode',
  ParentGroupID: 'ParentGroupID',
  FieldTitleDefaultWidth: 'FieldTitleDefaultWidth',
  TitleWidth: 'TitleWidth',
  FieldWidth: 'FieldWidth',
  Orientation: 'Orientation',
  GroupType: 'GroupType',
  IsCollapsed: 'IsCollapsed',
  ObjectFieldID_IsRequired: 'ObjectFieldID.IsRequired',
  ViewMode: 'ViewMode',
  IsDisplayField: 'IsDisplayField',
  DisplayType: 'DisplayType',
  Icon: 'Icon',
  Style: 'Style',
  MultiselectViewCount: 'MultiselectViewCount',
  DetailFormID: 'DetailFormID',
  DetailFormID_Type: 'DetailFormID.Type',
  DetailFormID_Name: 'DetailFormID.Name',
  MasterObjectFieldID: 'MasterObjectFieldID',
  DetailObjectFieldID: 'DetailObjectFieldID',
  MasterObjectFieldID_Name: 'MasterObjectFieldID.Name',
  DetailObjectFieldID_Name: 'DetailObjectFieldID.Name',
  EventTypeID: 'EventTypeID',
  FormFieldID: 'FormFieldID',
  FormFilterFieldID: 'FormFilterFieldID',
  FunctionName: 'FunctionName',
  FormButtonID: 'FormButtonID',
  CustomParameters: 'CustomParameters',
  CustomOperation: 'CustomOperation',
  LinkedObjectID: 'LinkedObjectID',
  LinkedObjectID_Name: 'LinkedObjectID.Name',
  LinkedObjectID_DisplayObjectFieldID: 'LinkedObjectID.DisplayObjectFieldID',
  LinkedObjectID_DisplayObjectFieldID_Name: 'LinkedObjectID.DisplayObjectFieldID.Name',
  LinkedObjectID_DisplayObjectFieldID_FieldType: 'LinkedObjectID.DisplayObjectFieldID.FieldType',
  LinkedObjectID_KeyObjectFieldID: 'LinkedObjectID.KeyObjectFieldID',
  LinkedObjectID_KeyObjectFieldID_Name: 'LinkedObjectID.KeyObjectFieldID.Name',
  LinkedObjectID_KeyObjectFieldID_FieldType: 'LinkedObjectID.KeyObjectFieldID.FieldType',
  RegionalLocale: 'RegionalLocale',
  AppLang: 'AppLang',
  AppTheme: 'AppTheme',
  AppThemeDrawer: 'AppThemeDrawer',
  AppThemeTopBar: 'AppThemeTopBar',
  AppDrawerSide: 'AppDrawerSide',
  ParentFieldID: 'ParentFieldID',
  ParentFieldID_Name: 'ParentFieldID.Name',
  ParentFieldID_Type: 'ParentFieldID.Type',
  TitlePosition: 'TitlePosition',
  TitleType: 'TitleType',
  ParameterFieldName: 'ParameterFieldName',
  ReportURL: 'ReportURL',
  MainGroupID: 'MainGroupID',
  NestedFieldID: 'NestedFieldID',
  NestedFieldID_Name: 'NestedFieldID.Name',
  RelationType: 'RelationType',
  IsShowInMainForm: 'IsShowInMainForm',
  IsCustomizable: 'IsCustomizable',
  ActionFormID_Type: 'ActionFormID.Type',
  ActionFormID_Name: 'ActionFormID.Name',
  ActionFormID_ObjectID_Name: 'ActionFormID.ObjectID.Name',
  IsRequired: 'IsRequired',
  IsShowRoot: 'IsShowRoot',
  TreeIconField: 'TreeIconField',
  TreeTextField: 'TreeTextField',
  CheckField: 'CheckField',
  RootName: 'RootName',
  DisplayCustomParam: 'DisplayCustomParam',
  TotalFunction: 'TotalFunction',
  DateFrom: 'DateFrom',
  DateTo: 'DateTo',
  EndValueField: 'EndValueField',
  IsShowTotals: 'IsShowTotals',
  TotalRowKind: 'TotalRowKind',
  ButtonID: 'ButtonID',
  SourceFieldID: 'SourceFieldID',
  DestinationFieldID: 'DestinationFieldID',
  DestinationFilterID: 'DestinationFilterID',
  SourceFieldID_Name: 'SourceFieldID.Name',
  SourceAppObjectFieldName: 'SourceAppObjectFieldName',
  DestinationFieldID_Name: 'DestinationFieldID.Name',
  DestinationFilterID_Name: 'DestinationFilterID.Name',
  DestAppObjectFilterName: 'DestAppObjectFilterName',
  DetailFormID_ObjectID_Name: 'DetailFormID.ObjectID.Name',
  DestinationFilterID_IsMultiselect: 'DestinationFilterID.IsMultiselect',
  IconSize: 'IconSize',
  IsHideInOtherActions: 'IsHideInOtherActions',
  CustomSettingsObject: 'CustomSettingsObject',
  SelectFormFilter: 'SelectFormFilter',
  DestinationFilter: 'DestinationFilter',
  SourceField: 'SourceField',
  PaginationType: 'PaginationType',
  RowsPerPage: 'RowsPerPage',
  Page: 'Page',
  Splitter: 'Splitter',
  RowSelectionMode: 'RowSelectionMode',
  IsUseHierarchyRequest: 'IsUseHierarchyRequest',
  HierarchyRequestKind: 'HierarchyRequestKind',
  HierarchyChildLevel: 'HierarchyChildLevel',
  HierarchyParentColumn: 'HierarchyParentColumn',
  HierarchyHasChildRowsColumn: 'HierarchyHasChildRowsColumn',
  FilterBody: 'FilterBody',
  IsUserByDefault: 'IsUserByDefault',
  IsFavorite: 'IsFavorite',
  LastModifyDate: 'LastModifyDate',
  MasterFormID: 'MasterFormID',
  IsEditButtonByDefault: 'IsEditButtonByDefault',
  DocURL: 'DocURL',
  Hotkey: 'Hotkey',
  Hint: 'Hint',
  IsAsyncLoad: 'IsAsyncLoad',
  IsActive: 'IsActive',
} as const;

export const system = {
  _Changed: '_Changed',
  THEME: {
    LIGHT: 'LIGHT',
    BLUE: 'BLUE',
  },
  ButtonsColumn: '__ButtonsColumn',
  ColumnSpacer: '__columnSpacer',
  InlineEditColumn: '__InlineEditColumn',
  HasChildren: '_hasChildRecords',
  DIRECTION: {
    LTR: 'ltr',
    LEFT: 'left',
    RIGHT: 'right',
  },
  VIEW_MODE: {
    FULL_SCREEN: 1,
    MODAL: 2,
    IN_NEW_WINDOW: 3,
  },
  MIN_PASSWORD_LENGTH: 5,
  EDIT_MODE: {
    FORM: 0,
    INLINE: 1,
    CELL: 2,
    CHECK: 3,
  },
  FORM_STATUS: {
    INIT: 'init',
    SHOW: 'show',
  },
  FORM_EDIT_CREATE_MODE: {
    ADD: 'add',
    ADD_CHILD: 'addChild',
    EDIT: 'edit',
    VIEW: 'view',
    MULTI_EDIT: 'multiEdit',
    REPORT: 'report',
    DELETE: 'delete',
    FORM_SELECT: 'formSelect',
    FORM_MULTI_SELECT: 'formMultiSelect',
    FORM_LIST: 'listForm',
    FILTER_MODAL: 'filterModal',
    QR_MODAL: 'qrModal',
  },
  LOAD_ALL_CONSTANTS: {
    LOAD_ALL_SETTINGS: 'loadAllSettings',
    DEFAULT_SCROLLING_SETTINGS: 'defaultScrollingSettings',
  },
  FORM_ORIENTATION: {
    HORIZONTAL: 1,
    VERTICAL: 2,
  },
  LANGUAGE: 'language',
  LANGUAGES: {
    RUSSIAN: 'Русский',
    UKRAINIAN: 'Українська',
    ENGLISH: 'English',
  },
  USER_SETTINGS: {
    APP_SETTINGS: 'app_settings',
    TITLE: 'title',
    REGIONAL_SETTINGS: 'regionalsettings',
    EDITOR_STYLING_MODE: 'editorStylingMode',
    APPLICATION_LANG: 'APPLICATION_LANG',
    GLOBAL_ROWS_SIZE: 'row_sizes',
    ALTER_LINES_COLOR: 'alt_colors',
    HORIZ_SEPAR_LINES: 'horizontal_separate_lines',
    VERT_SEPAR_LINES: 'vertical_separate_lines',
  },
  SYSTEM_BUTTONS: {
    TOGGLE_TOTALS: 'toggleTotals',
    TOGGLE_TOTALS_NONE: 'toggleTotalsNone',
    TOGGLE_TOTALS_BY_SELECTED: 'toggleTotalsBySelected',
    TOGGLE_TOTALS_ALL_DATA: 'toggleTotalsAllData',
    EXPORT_ALL: 'exportAll',
    EXPORT_SELECTED: 'exportSelected',
    REVERT_TABLE_OPTIONS: 'revertTableOptions',
    COPY_FILTER_AS_URL: 'copyFilterAsURL',
  },
  NUMBER_OF_RECENT_FILTER_TEMPLATES: 5,
  FILE_NOT_FOUND: 'FILE_NOT_FOUND',
  FUNCTION_NOT_FOUND: 'FUNCTION_NOT_FOUND',
  USER_NOT_FOUND: 'UserNotFoundError',
  uid: 'uid',
  login: 'login',
  page404: 'page404',
  personID: 'personID',
  current_user: 'current_user',
  ReturnUrl: 'return_url',
  FILTER_QUERY: 'FILTER_QUERY',
  FIELD_TYPE: {
    TEXT: 0,
    NUMBER: 1,
    DATE: 2,
    BOOL: 3,
    MEMO: 5,
    BUTTON: 'button',
    DECORATION: 'decoration',
    COMBINED: 'combined',
  },
  TITLE_POSITION: {
    LEFT: 1,
    RIGHT: 2,
    TOP: 0,
  },
  TITLE_TYPE: {
    ICON: 1,
    TITLE: 2,
    ICON_AND_TITLE: 3,
  },
  FILTER_SENDERS: {
    FORM: 'formFilters',
    LAYOUT: 'layoutFilters',
    TABLE: 'tableFilters',
    FORM_EDIT: 'formEdit',
    SUBFORM: 'subForm',
  },
  USER_SCRIPTS_INITIATOR: {
    TABLE: 'table',
    TREE: 'tree',
    FORM_EDIT: 'formEdit',
  },
  DEF_FE_BUTTON_EVENTS: {
    SUBMIT: 'submit',
    APPLY: 'apply',
    CANCEL: 'cancel',
  },
  DX_CONTROLS: {
    dxTextArea: 'dxTextArea',
    dxTextBox: 'dxTextBox',
    dxNumberBox: 'dxNumberBox',
    dxDateBox: 'dxDateBox',
    dxCheckBox: 'dxCheckBox',
    dxButton: 'dxButton',
    dxSelectBox: 'dxSelectBox',
    dxTagBox: 'dxTagBox',
    dxHtmlEditor: 'dxHtmlEditor',
    dxFileUploader: 'dxFileUploader',
    dxSwitch: 'dxSwitch',
    dxImage: 'dxImage',
    dxDropDownBox: 'dxDropDownBox',
    extQrScanner: 'extQrScanner',
    dxDiagram: 'dxDiagram,',
    dxRangeSlider: 'dxRangeSlider',
    dxSlider: 'dxSlider',
    dxListSelector: 'dxListSelector',
    extDualListSelector: 'extDualListSelector',
    extFileViewer: 'extFileViewer',
    dxColorBox: 'dxColorBox',
  },
  FORM_STATE_PROPS: {
    FIELDS: 'fields',
  },
  TOOLBAR_BTN_CLASSES: {
    EDIT: 'table-form-edit-button',
    MULTI_EDIT: 'table-form-multi-edit-button',
    DEL: 'table-form-delete-button',
    SELECT: 'table-form-select-all',
    DESELECT: 'table-form-deselect-all',
    GOTO: 'table-form-goto-button',
    REPORTS: 'table-form-reports-button',
    FILTER: 'table-form-dock-filter-button',
    ADD: 'table-form-add-button',
    REFRESH: 'table-form-refresh-button',
    EXPORT: 'table-form-export-button',
    COPY: 'table-form-copy-button',
    CLEAR_QUICK_FILTER: 'clear-quick-filter',
    SET_IS_PASSED: 'set-is-passed',
    CLEAR_IS_PASSED: 'clear-is-passed',
    ADD_CHILD: 'table-form-add-child',
    HIDDEN_MENU_ITEM: 'd5-hidden-menu-item',
    REPORT_GROUP: 'table-form-report-group',
    SHOW_FILTER_DOCK_PANEL: 'show-filter-dock-panel',
    SHOW_EDIT_DOCK_PANEL: 'show-edit-dock-panel',
  },
  MAIN_TOP_CONTENT_BUTTONS: {
    ProfileSettings: 'ProfileSettings',
    SignOut: 'SignOut',
    StartWork: 'StartWork',
    CreateProject: 'CreateProject',
    Orders: 'Orders',
    Groups: 'Groups',
    CreateList: 'CreateList',
    Filter: 'Filter',
    EditFilters: 'EditFilters',
    Documentation: 'Documentation',
    Russian: 'Russian',
    English: 'English',
    Ukrainian: 'Ukrainian',
  },
  LAYOUT_CLASSNAMES: {
    TABLE_CN: '--table',
    SUBFORMS_CN: '--subforms',
    DECORS_CN: '--decors',
    FIELDS_CN: '--fields',
    HIDDEN_CN: '--hidden',
    DEFAULT_CN: '--default',
    EMPTY_CN: '--empty',
    ACCORDION_CN: '--accordion',
    ONLY_SMALL_ITEMS_CN: '--only-small-items',
    HAS_ACCORDION_CN: '--has-accordion',
    EDIT_SUBFORM_CN: '--edit-subform',
    RESIZABLES: '--resizable-items',
    GROUP_CN: 'group',
    ITEM_CN: 'item',
    FORM_EDIT: 'form-edit',
    D5_SELECTED_CELL: 'd5-selected-cell',
  },
  HEADER_ICON_COLOR: {
    FILTERS_SELECTED: '#0049FF',
    FILTERS_NOT_SELECTED: '#6E7492',
  },
  LINKS: {
    TYPES: {
      LINK: 'Link',
      FORM: 'Form',
      GOTO: 'Goto',
    },
    OPEN: {
      MODAL: 'Modal',
      FULL_SCREEN: 'FullScreen',
      IN_NEW_WINDOW: 'InNewWindow',
      IN_NEW_TAB: 'InNewTab',
      IN_CURRENT: 'InCurrent',
    },
  },
  LABEL_CALC: {
    FONT: '13px / 16.7141px Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial, sans-serif',
  },
  API_OPERATION: {
    CREATE: 'Ins',
    READ: 'List',
    UPDATE: 'Mod',
    DELETE: 'Del',
  },
  SYSTEM_PREFIX: '_system_',
  ENUM_DS: {
    VALUE_EXPR: 'key',
    DISPLAY_EXPR: 'value',
  },
  TEMPLATE_TYPE: {
    BUTTON: 'button',
    TITLE: 'title',
  },
  ORIENTATION: {
    NUM: {
      COLUMN: 1,
      ROW: 2,
    },
    STR: {
      COLUMN: 'col',
      ROW: 'row',
    },
  },
  LAYOUT_TYPE: {
    FILTER: 'filter',
    SUBFORM: 'subform',
    DECORATION: 'decoration',
    FORM_FIELD: 'form_field',
    BUTTON: 'button',
    GROUP: 'group',
    TABLE: 'table',
    KANBAN: 'kanban',
  },
  CHANGE_EVENT: 'change',
  EDITOR_CLASSES: {
    FIELD_CONTAINER: 'field-container',
    D5FIELD: 'd5-field',
    FC_BETWEEN: 'field-container-between',
  },
  MSGS: {
    MustImplInAChild: 'Must be implemented in a child',
  },
  VALIDATION_GROUPS: {
    FORM_EDIT: 'form_edit',
    LAYOUT: {
      FILTER: 'layout-filter',
    },
  },
  DISPLAY_TYPE: {
    TEXT: 0,
    LINK: 1,
    IMAGE: 2,
    PASSWORD: 3,
    PERIOD: 4,
    ICON: 5,
    FILE: 6,
    HTML_EDITOR: 7,
    SWITCH: 8,
    DROP_DOWN_BOX: 9,
    ENUMERATOR: 10,
    QR_SCANNER: 11,
    LOOKUP: 12,
    DIAGRAM: 13,
    SLIDER: 14,
    RANGE_SLIDER: 15,
    LIST_SELECTOR: 16,
    DUAL_LIST_SELECTOR: 17,
    COLOR_BOX: 18,
    LABEL: 19,
    FILE_VIEWER: 20,
  },
  RIGHT_BAR_WIDTH: 35,
  MAX_DISPLAYED_TAGS: 3,
  FILE_NAMES: {
    CONFIG: 'config',
    DIAGRAM_CONFIG: 'diagram-config',
  },
  DISPLAY_CUSTOM_PARAMS: {
    PARENT_FIELD: 'ParentField',
  },
  CONFIG_PARAMS: {
    APPLICATION: 'application',
    HOME_PAGE: 'homePage',
    AUTH_TYPE: 'authType',
    ALLOW_CHANGE_THEME: 'isAllowChangeTheme',
    CUSTOM_PAGE: 'customPage',
    DEFAULT_FILE: 'defaultFile',
    DOC_URL: 'docURL',
    FACEBOOK: 'facebook',
    FAVICON: 'favicon',
    GOOGLE: 'google',
    LINKEDIN: 'linkedIn',
    LOGO: 'logo',
    LOGO_HEIGHT: 'logoHeight',
    LOGIN_PAGE: 'loginPage',
    REGISTRATION_ALLOWED: 'registrationAllowed',
    REGISTRATION_TYPE: 'registrationType',
    SUBSYSTEM_PANEL: 'subsystemsPanel',
    SYSTEM_TOOLBAR: 'systemToolbar',
    USER_SETTINGS_IS_LOADED: 'userSettingsIsLoaded',
    TITLE: 'title',
    WINDOW_TITLE: 'windowTitle',
    FORM_TITLE_PARAM: '$formTitle',
    VIEW_BOX: 'viewBox',
    CUSTOM_FONTS: 'customFonts',
    SUCCESS_MESSAGE_TIMEOUT: 'successMessageTimeout',
    ERROR_MESSAGE_TIMEOUT: 'errorMessageTimeout',
  },
  FONTAWESOME: {
    FA_GOOGLE: 'fab fa-google',
    FA_FACEBOOK: 'fab fa-facebook-f',
    FA_LINKEDIN: 'fab fa-linkedin-in',
  },

  COMPONENT_CLASSES: {
    d5LinkClass: 'd5-link dx-link link-control',
    IS_PRESSED_CLASS: 'd5-is-pressed',
  },
  DEFAULT_LOCALS: {
    LINE_HEIGHT: 1.32,
    MESSAGE_TIMEOUT: 10000,
    MESSAGE_TIMEOUT_LONG: 999999999,
    DEFAULT: 'default',
    PRECISION: 0,
    CELL_INNER_PADDING: '5px',
  },
  PAGES: {
    LOGIN: 'loginPage',
    REGISTRATION: 'registrationPage',
    FORGOT_PASSWORD: 'forgotPasswordPage',
    RESTORE_PASSWORD: 'restorePasswordPage',
  },
  INPUT_PLACEHOLDERS: {
    PASSWORD: 'Password',
    NAME: 'Name',
    EMAIL: 'Email',
    LOGIN: 'Login',
  },
  LOGIN_PAGE_CONSTANTS: {
    SIGN_IN: 'Sign In',
    SIGN_UP: 'Sign Up',
    ENTER_PERSONAL_DETAILS: 'Enter your personal details and start journey with us',
    FORGOT_PASSWORD: 'Forgot your password?',
    ID: {
      SIGN_IN_BUTTON: 'signInButton',
      SIGN_IN_BUTTON_ADDITIONAL: 'signInButtonAdditional',
      SEND_BUTTON: 'sendButton',
      SIGN_UP_BUTTON: 'signUpButton',
      SIGN_UP_BUTTON_ADDITIONAL: 'signUpButtonAdditional',
      FORGOT_PASSWORD: 'forgotPassword',
      LOGIN_FIELD: 'loginField',
      EMAIL_FIELD: 'emailField',
      NAME_FIELD: 'nameField',
      PASSWORD_FIELD: 'passwordField',
      CONFIRM_FIELD: 'confirmField',
    },
  },
  ROW_SELECTION_MODE: {
    REGULAR: 'single',
    CHECK_BOX: 'multiple',
    REGULAR_NUM: 1,
    CHECK_BOX_NUM: 2,
  },
  TREE_REFRESH_NODE_CASE: {
    EMPTY_PARENT: 'emptyParent',
    HAS_ID: 'hasID',
    HAS_PARENT: 'hasParent',
  },
  SYS_LOGIN: 'Sys_Login',
  REQUEST: 'Request',
  HAS_DEFAULT_FILTER: 'has-default-filter',
  CONTROL_SEARCH_MODE: 'control-searchMode',
  QR_CAM: 'qr-cam',
  VISIBLE_FILTERS: 'visible-filters',
  MASTER_FORM_ID: 'MasterFormID',
  MASTER_FORM_NAME: 'MasterFormName',
  SERVICE_PREFIX: {
    ADMIN: 'admin',
    CONFIGURATOR: 'configurator',
  },
  COMBINED_COLUMN_KEYS: {
    TREE_TEXT_FIELD: 'treeTextField',
    TREE_ICON_FIELD: 'treeIconField',
    TREE_CHECK_FIELD: 'treeCheckField',
  },
  SUBFORM_POSITION: {
    AUTO: 1,
    FILTER_DOCK_PANEL: 2,
  },
  LOCALE_SEPARATORS: {
    ru: {
      thousandSeparator: ' ',
      fractionalSeparator: ',',
    },
    uk: {
      thousandSeparator: ' ',
      fractionalSeparator: ',',
    },
    en: {
      thousandSeparator: ',',
      fractionalSeparator: '.',
    },
  },
} as const;

export const LOGIN_PAGE_US = {
  [system.CONFIG_PARAMS.CUSTOM_PAGE]: null,
  [system.CONFIG_PARAMS.AUTH_TYPE]: {
    [system.CONFIG_PARAMS.GOOGLE]: false,
    [system.CONFIG_PARAMS.FACEBOOK]: false,
    [system.CONFIG_PARAMS.LINKEDIN]: false,
  },
  [system.CONFIG_PARAMS.REGISTRATION_ALLOWED]: false,
  [system.CONFIG_PARAMS.REGISTRATION_TYPE]: {
    [system.CONFIG_PARAMS.GOOGLE]: false,
    [system.CONFIG_PARAMS.FACEBOOK]: false,
    [system.CONFIG_PARAMS.LINKEDIN]: false,
  },
} as const;

export const SIMPLIFIED_LAYOUT_QUERY = 'simpleview';
export const SUBSYSTEM_STR = 'section';
export const FORM_PREVIEW = 'form-preview';
export const KANBAN_URL = 'kanban';
export const FILTER_URL = 'filter';
export const RESTORE_PASSWORD = '/restore-password';
export const USER_ACTIVATION = '/user-activation';
export const LOGIN_ROUTE = '/login';
export const SECTION_ROUTE = '/' + SUBSYSTEM_STR;
export const FILTER_ROUTE = '/' + FILTER_URL;
export const REPORT_VIEWER_STR = 'wapi/dxreport/viewer';
export const FILE_PREVIEW_FORM = 'FilePreviewForm';
