import './wdyr';
import {initThemes} from 'initTheme';
import {getConfigData} from 'configCustom';
import {saveDataToLS} from 'utilsOld/configJSONUtils';
import loadDiagramConfig from 'components/newReactFormFields/Diagram/loadDiagramConfig';
import {UserSettingsState} from 'services/userSettings/types';
import {system} from './services/objects';
import './shims';
import './index.scss';

(async () => {
  const [userConfig, diagramConfig] = await Promise.all([getConfigData(), loadDiagramConfig()]);

  const config: {
    application: UserSettingsState;
  } = {
    ...userConfig,
    [system.CONFIG_PARAMS.APPLICATION]: {
      ...userConfig[system.CONFIG_PARAMS.APPLICATION],
      diagramConfig,
    },
  };

  // @ts-ignore
  saveDataToLS(config); //set default themes to local storage app_settings

  initThemes(); // get themes from local storage app_settings

  const {default: initApplication} = await import(/* webpackChunkName: "app" */ './App');
  initApplication(config);
})();
