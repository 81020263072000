import * as util from './utility-inner';

export const each = util.each;

export function map(obj, iteratee, context) {
  iteratee = util.cb(iteratee, context);
  let keys = !util.isArrayLike(obj) && util.keys(obj),
    length = (keys || obj).length,
    results = Array(length);
  for (let index = 0; index < length; index++) {
    let currentKey = keys ? keys[index] : index;
    results[index] = iteratee(obj[currentKey], currentKey, obj);
  }
  return results;
}

export function mapObject(obj, iteratee, context) {
  iteratee = util.cb(iteratee, context);
  const keys = util.keys(obj),
    length = keys.length,
    results = {};
  for (let index = 0; index < length; index++) {
    let currentKey = keys[index];
    results[currentKey] = iteratee(obj[currentKey], currentKey, obj);
  }
  return results;
}

export function find(list, predicate) {
  if (Array.isArray(list)) {
    return list.filter(predicate)[0];
  }
  return undefined;
}

export const findIndex = util.createPredicateIndexFinder(1);

export function findWhere(obj, attrs) {
  return util.find(obj, util.matcher(attrs));
}

export function sortBy(obj, iteratee, context) {
  let index = 0;
  iteratee = util.cb(iteratee, context);
  return pluck(
    map(obj, function (value, key, list) {
      return {
        value: value,
        index: index++,
        criteria: iteratee(value, key, list),
      };
    }).sort(function (left, right) {
      let a = left.criteria;
      let b = right.criteria;
      if (a !== b) {
        if (a > b || a === void 0) return 1;
        if (a < b || b === void 0) return -1;
      }
      return left.index - right.index;
    }),
    'value'
  );
}

export const groupBy = util.group(function (result, value, key) {
  if (util.has(result, key)) result[key].push(value);
  else result[key] = [value];
});

export const indexBy = util.group(function (result, value, key) {
  result[key] = value;
});

export function pluck(obj, key) {
  return map(obj, util.property(key));
}

export function uniq(array, isSorted, iteratee, context) {
  if (!isBoolean(isSorted)) {
    context = iteratee;
    iteratee = isSorted;
    isSorted = false;
  }
  if (iteratee != null) iteratee = util.cb(iteratee, context);
  const result = [];
  let seen = [];
  for (let i = 0, length = util.getLength(array); i < length; i++) {
    let value = array[i],
      computed = iteratee ? iteratee(value, i, array) : value;
    if (isSorted && !iteratee) {
      if (!i || seen !== computed) result.push(value);
      seen = computed;
    } else if (iteratee) {
      if (!contains(seen, computed)) {
        seen.push(computed);
        result.push(value);
      }
    } else if (!contains(result, value)) {
      result.push(value);
    }
  }
  return result;
}

export function isBoolean(obj) {
  return obj === true || obj === false || toString.call(obj) === '[object Boolean]';
}

export function contains(obj, item, fromIndex, guard) {
  if (!util.isArrayLike(obj)) obj = values(obj);
  if (typeof fromIndex != 'number' || guard) fromIndex = 0;
  return util.indexOf(obj, item, fromIndex) >= 0;
}

export function values(obj) {
  const keys = util.keys(obj);
  const length = keys.length;
  let values = Array(length);
  for (let i = 0; i < length; i++) {
    values[i] = obj[keys[i]];
  }
  return values;
}

export function isEmpty(obj) {
  if (obj == null) return true;
  if (
    util.isArrayLike(obj) &&
    (Array.isArray(obj) || typeof obj === 'string' || obj instanceof String || util.has(obj, 'callee'))
  )
    return obj.length === 0;
  return util.keys(obj).length === 0;
}

export function flatten(array, prop, _depth) {
  if (!Array.isArray(array)) throw new TypeError('flatten(): Parameter "array" must be an array of objects');

  if (typeof prop !== 'string') throw new TypeError('flatten(): Parameter "prop" must be a type of string');

  function* innerflatten(array, prop, depth) {
    if (depth === undefined) {
      depth = 1;
    }
    for (const item of array) {
      if (Array.isArray(item[prop]) && depth > 0) {
        yield item;
        // @ts-ignore
        yield* innerflatten(item[prop], depth - 1);
      } else {
        yield item;
      }
    }
  }

  return [...innerflatten(...arguments)];
}
