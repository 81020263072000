import {fields, system} from 'services/objects';
import {Locale, LanguageCode} from '../interfaces/global-interfaces';

/**
 * !!!!!!!!!Внимание в этот модуль нельзя подключать node_modules
 */

if (!getApplicationLang()) {
  setSettings(system.USER_SETTINGS.APPLICATION_LANG, LanguageCode.ua);
}

export function _createSettingName(name) {
  return name.toLowerCase();
}

export function setSettings(name, value) {
  let appSets = JSON.parse(localStorage.getItem(system.USER_SETTINGS.APP_SETTINGS) || '{}');
  appSets[_createSettingName(name)] = value;
  localStorage.setItem(system.USER_SETTINGS.APP_SETTINGS, JSON.stringify(appSets));
}

export function getSettings() {
  return JSON.parse(localStorage.getItem(system.USER_SETTINGS.APP_SETTINGS) || '{}');
}

export function clearLocalAndSessionStorage() {
  const currentUser = JSON.parse(localStorage.getItem(system.current_user) || '{}');
  localStorage.clear();
  localStorage.setItem(system.current_user, JSON.stringify(currentUser));
  sessionStorage.clear();
}

export function getRegionalSettings() {
  const regionalSettings = getSettings()[_createSettingName(system.USER_SETTINGS.REGIONAL_SETTINGS)];
  if (!regionalSettings || !Locale.hasOwnProperty(regionalSettings[fields.RegionalLocale])) {
    return {[fields.RegionalLocale]: Locale.uk};
  }
  return regionalSettings;
}

export function getApplicationLang() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.APPLICATION_LANG)];
}

export function getAppRowSize() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.GLOBAL_ROWS_SIZE)];
}

export function getAppAltColor() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.ALTER_LINES_COLOR)];
}

export function getAppHorizSeparLines() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.HORIZ_SEPAR_LINES)];
}

export function getAppVertSeparLines() {
  return getSettings()[_createSettingName(system.USER_SETTINGS.VERT_SEPAR_LINES)];
}
