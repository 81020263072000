export function each(obj, iteratee, context) {
  iteratee = optimizeCb(iteratee, context);
  let i, length;
  if (isArrayLike(obj)) {
    for (i = 0, length = obj.length; i < length; i++) {
      iteratee(obj[i], i, obj);
    }
  } else {
    let _keys = keys(obj);
    for (i = 0, length = _keys.length; i < length; i++) {
      iteratee(obj[_keys[i]], _keys[i], obj);
    }
  }
  return obj;
}

export const isArrayLike = collection => {
  const length = getLength(collection)
  return typeof length == 'number' && length >= 0 && length <= MAX_ARRAY_INDEX
}

export const cb = (value, context, argCount) => {
  if (iteratee !== builtinIteratee) return iteratee(value, context)
  if (value == null) return identity
  if (isFunction(value)) return optimizeCb(value, context, argCount)
  if (isObject(value) && !Array.isArray(value)) return matcher(value)
  return property(value)
}

export const property = path => {
  if (!Array.isArray(path)) {
    return shallowProperty(path)
  }
  return function (obj) {
    return deepGet(obj, path)
  }
}

export const optimizeCb = (func, context, argCount) => {
  if (context === void 0) return func
  switch (argCount == null ? 3 : argCount) {
    case 1:
      return function (value) {
        return func.call(context, value)
      }
    // The 2-argument case is omitted because we’re not using it.
    case 3:
      return function (value, index, collection) {
        return func.call(context, value, index, collection)
      }
    case 4:
      return function (accumulator, value, index, collection) {
        return func.call(context, accumulator, value, index, collection)
      }
    default:
      break
  }
  return function () {
    return func.apply(context, arguments)
  }
}

export const group = (behavior, partition) => {
  return function (obj, iteratee, context) {
    const result = partition ? [[], []] : {}
    iteratee = cb(iteratee, context)
    each(obj, function (value, index) {
      let key = iteratee(value, index, obj)
      behavior(result, value, key)
    })
    return result
  }
}

export const has = (obj, path) => {
  return obj != null && hasOwnProperty.call(obj, path)
}

export const find = (obj, predicate, context) => {
  const keyFinder = isArrayLike(obj) ? createPredicateIndexFinder(1) : findKey
  let key = keyFinder(obj, predicate, context)
  if (key !== void 0 && key !== -1) return obj[key]
}

export const matcher = attrs => {
  attrs = extendOwn({}, attrs)
  return function (obj) {
    return isMatch(obj, attrs)
  }
}

export const indexOf = createIndexFinder(1, createPredicateIndexFinder(1), sortedIndex)

function sortedIndex (array, obj, iteratee, context) {
  iteratee = cb(iteratee, context, 1)
  const value = iteratee(obj)
  let low = 0, high = getLength(array)
  while (low < high) {
    let mid = Math.floor((low + high) / 2)
    if (iteratee(array[mid]) < value) {
        low = mid + 1
    } else {
        high = mid
    }
  }
  return low
}

function createIndexFinder (dir, predicateFind, sortedIndex) {
  return function (array, item, idx) {
    let i = 0, length = getLength(array)
    if (typeof idx == 'number') {
      if (dir > 0) {
        i = idx >= 0 ? idx : Math.max(idx + length, i)
      } else {
        length = idx >= 0 ? Math.min(idx + 1, length) : idx + length + 1
      }
    } else if (sortedIndex && idx && length) {
      idx = sortedIndex(array, item)
      return array[idx] === item ? idx : -1
    }
    if (item !== item) {
      idx = predicateFind(Array.prototype.slice.call(array, i, length), isNaN)
      return idx >= 0 ? idx + i : -1
    }
    for (idx = dir > 0 ? i : length - 1; idx >= 0 && idx < length; idx += dir) {
      if (array[idx] === item) return idx
    }
    return -1
  }
}

const shallowProperty = (key) => {
  return function (obj) {
    return obj == null ? void 0 : obj[key]
  }
}

const MAX_ARRAY_INDEX = Math.pow(2, 53) - 1
export const getLength = shallowProperty('length')

const deepGet = (obj, path) => {
  let length = path.length
  for (let i = 0; i < length; i++) {
    if (obj == null) return void 0
    obj = obj[path[i]]
  }
  return length ? obj : void 0
}

let builtinIteratee

const identity = value => value

const isMatch = (object, attrs) => {
  let keys = Object.keys(attrs), length = keys.length
  if (object == null) return !length
  let obj = Object(object)
  for (let i = 0; i < length; i++) {
    let key = keys[i]
    if (attrs[key] !== obj[key] || !(key in obj)) return false
  }
  return true
}

const createAssigner = (keysFunc, defaults) => {
  return function (obj) {
    let length = arguments.length
    if (defaults) obj = Object(obj)
    if (length < 2 || obj == null) return obj
    for (let index = 1; index < length; index++) {
      let source = arguments[index],
        keys = keysFunc(source),
        l = keys.length
      for (let i = 0; i < l; i++) {
        let key = keys[i]
        if (!defaults || obj[key] === void 0) obj[key] = source[key]
      }
    }
    return obj
  }
}

const extendOwn = createAssigner(keys)

const isObject = obj => {
  let type = typeof obj
  return ((type === 'function') || (type === 'object')) && !!obj
}

const isFunction = obj => typeof obj == 'function' || false

let iteratee = builtinIteratee = (value, context) => {
  return cb(value, context, Infinity)
}

export function createPredicateIndexFinder (dir) {
  return function (array, predicate, context) {
    predicate = cb(predicate, context)
    const length = getLength(array)
    let index = dir > 0 ? 0 : length - 1
    for (; index >= 0 && index < length; index += dir) {
      if (predicate(array[index], index, array)) return index
    }
    return -1
  }
}

const findKey = (obj, predicate, context) => {
  predicate = cb(predicate, context)
  let _keys = keys(obj), key
  for (let i = 0, length = _keys.length; i < length; i++) {
    key = _keys[i]
    if (predicate(obj[key], key, obj)) return key
  }
}

export function keys (obj) {
  if (!isObject(obj)) return []
  if (Object.keys) return Object.keys(obj)
  const keys = []
  for (let key in obj) if (has(obj, key)) keys.push(key)
  return keys
}
