import {system} from 'services/objects';
import {D5Error} from 'services/SecondaryMethods/errors';
import {DiagramCategory, ICustomShapes} from './interfaces';
import {groupBy} from 'utilsOld/utility';

const loadDiagramConfig = async (): Promise<{
  customShapes?: ICustomShapes[];
  categories?: DiagramCategory[];
}> => {
  try {
    const response = await fetch(`/user-data/resources/${system.FILE_NAMES.DIAGRAM_CONFIG}.json?${Math.random()}`);
    if (!response.ok) {
      return {customShapes: undefined, categories: undefined};
    }

    const {customShapes} = await response.json();

    const grouped = groupBy(customShapes || [], 'category') as Record<string, ICustomShapes[]>;

    let categories: DiagramCategory[] = Object.entries(grouped).map(([category, shapes]) => {
      return {
        displayMode: shapes.some(shape => shape.backgroundImageUrl) ? 'icons' : 'texts',
        // shapes,
        category,
      } as const;
    });

    return {customShapes, categories};
  } catch (e) {
    D5Error.log('E1021');
  }

  return {customShapes: undefined, categories: undefined};
};

export default loadDiagramConfig;
